* {
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
}

.markdown-wrap img {
    max-width: 100%;
}

.markdown-wrap table {
    border-collapse: collapse;
}

.markdown-wrap table thead {
    background-color: rgba(0, 0, 0, 0.2);
}

.markdown-wrap table th,
.markdown-wrap table td {
    border: 1px solid #ddd;
    padding: 8px
}

#tsparticles {
    width: 100%;
    height: 100%;
    z-index: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mantine-AppShell-main::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mantine-AppShell-main {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.richtext-customized {
    min-height: 30vh;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}